export const createDate = (timestamp, format) => {
    if (typeof timestamp === 'object') {
        return timestamp;
    }
    if (typeof timestamp === 'string') {
        const date = new Date();
        switch (timestamp) {
            case 'now':
            case 'today':
                return date;

            case 'tomorrow': {
                date.setDate(date.getDate() + 1);
                return date;
            }

            default: {
                if (timestamp.startsWith('+')) { // add days
                    date.setDate(date.getDate() + Number(timestamp.substring(1)));
                    return date;
                }
                if (timestamp.startsWith('-')) { // substract days
                    date.setDate(date.getDate() - Number(timestamp.substring(1)));
                    return date;
                }
                if (timestamp.startsWith('%')) { // this week
                    const weekDay = date.getDay();
                    const dateOffset = Number(timestamp.substring(1)) - weekDay;
                    date.setDate(date.getDate() + dateOffset);
                    return date;
                }
            }
        }
    }

    if (!timestamp) {
        return new Date();
    }
    if (format === 'ymd') {
        return new Date(timestamp);
    }
    return new Date(timestamp * 1000);
};

export const createDateFromTime = (time) => {
    time = time.toString();
    const strlength = time.length;
    return new Date(0, 0, 0, time.substring(0, strlength - 2), time.substring(strlength - 2), 0);
};

export default { createDate, createDateFromTime };

import Vue from 'vue';
import priceformat from '@/assets/js/priceformat';
import linkify from '@/assets/js/linkify';
import dates from '@/mixins/dates';
import { numbers_round } from '@projectfive/services';
import { parseTemplate } from '@/services/dataMethods';

Vue.filter('filename', (value) => {
    if (!value) { return ''; }
    if (value.startsWith('http')) { return value; }
    value = value.split('/').pop();
    return value;
});

Vue.filter('number', (val, decimals = 2, decimalSeparator = ',') => {
    if (Number.isNaN(val)) {
        console.warn('Amount is not a number. [format_number]', val);
        return val;
    }
    if (typeof val === 'string') {
        if (!val.length) { return val; }
        val = +val.replace(',', '.');
    }
    let number = val.toFixed(decimals);
    if (decimalSeparator !== '.') {
        number = number.replace('.', decimalSeparator);
    }
    return number;
});

Vue.filter('roundTo', numbers_round);
Vue.filter('priceformat', priceformat);
Vue.filter('linkify', linkify);

Vue.filter('date', (val, output, midnight, relative) => {
    if (val) {
        if (Array.isArray(val)) {
            return val.map((v) => dates.methods.timestamp_to(v, output, midnight, relative)).join(', ');
        }
        return dates.methods.timestamp_to(val, output, midnight, relative);
    }
    return '';
});

Vue.filter('datetime', (val, dateTimeFormatOptions, locale) => {
    if (val) {
        return new Intl.DateTimeFormat(locale, dateTimeFormatOptions).format(new Date(val));
    }
    return '';
});

Vue.filter('sanitizenumber', (value = 0, output) => {
    const type = typeof value;
    const data = { value, type };

    if (!['string', 'number'].includes(type)) { return { value: 0 }; }
    if (type === 'string') {
        data.value = value.replace(',', '.');
        if (data.value.trim().endsWith('%')) { // percentage
            data.type = 'percentage';
        }
        data.value = parseFloat(value);
        if (data.type === 'percentage') {
            data.divider = data.value / 100;
            data.divider_remaining = 1 - data.divider;
            data.divider_add = 1 + data.divider;
        } else if (data.value) {
            data.type = 'number';
        }
    }
    data.positive = data.value > 0;
    if (output === 'value') {
        return data.value;
    }
    return data;
});

Vue.filter('mentionReplace', (message, context) => {
    const liquidFilterRegex = /(?<filter>[a-z]+)(?:: ?(?:"|&quot;)(?<param>[^"]+)(?:"|&quot;))?/;
    if (message && typeof message === 'string') {
        const mentionReplacer = (_match, param1) => {
            const [key, ...filters] = param1.split('|');
            let replaced = parseTemplate(`this.${key.trim()}`, undefined, context);
            if (filters.length) {
                filters.forEach((filter) => {
                    const parts = liquidFilterRegex.exec(filter);
                    switch (parts?.groups?.filter) {
                        case 'date':
                            replaced = dates.methods.timestamp_to(replaced, parts.groups.param);
                            break;

                        case 'currency':
                            replaced = priceformat(typeof replaced === 'number' ? replaced : 0, parts.groups.param || undefined, true);
                            break;

                        case 'default':
                            replaced = replaced || parts.groups.param || '';
                            break;

                        default:
                            replaced = '';
                    }
                });
            }
            return replaced;
        };
        message = message.replace(/(?:<span data-type="mention" data-id="{{([^}]+)}}".+?<\/span>)/gm, mentionReplacer);
    }
    return message;
});

/**
 * Returns hostname from url
 * @param hostname defaults to url hostname
 * @returns hostname
 */
const namespaces: Record<string, string> = {};
function getNamespace(hostname = window.location.hostname): string {
    if (namespaces[hostname]) {
        return namespaces[hostname];
    }
    if (hostname === 'localhost') {
        return '';
    }
    let ns = hostname;
    if (ns.endsWith('.localhost')) {
        ns = ns.replace('.localhost', '');
        if (ns.startsWith('live.')) {
            ns = ns.substring(5);
        }
    }
    if (ns.endsWith('.netlify.app')) {
        ns = new URLSearchParams(window.location.search).get('namespace') || '';
    }
    namespaces[hostname] = ns;
    return ns;
}

export default getNamespace;

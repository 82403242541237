export const joinToPretty = ({
    list, key, max, overflowAmount, lang,
}) => {
    let valueCount = 0;
    return list.reduce((acc, cur, index) => {
        if (!max || valueCount < max) {
            const val = key ? cur[key] || (lang && cur[`${key}_${lang}`]) : cur;
            if (val) {
                let separator = (acc) ? ', ' : '';
                if (index + 1 === list.length && acc) {
                    separator = ' & ';
                }
                acc += `${separator}${val}`;
                valueCount += 1;

                if (overflowAmount && max && valueCount === max && list.length > index + 1) {
                    acc += ` + ${list.length - (index + 1)}`;
                }
            }
        }
        return acc;
    }, '');
};

export const pushUnique = ({
    list, item, key = 'id',
}) => {
    if (!Array.isArray(list)) {
        return [item];
    }
    return [...list.filter((it) => it[key] !== item[key]), item];
};

export default joinToPretty;

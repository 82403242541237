/** @flow */
import { createDate, createDateFromTime } from './create';

/**
 * Converts hours and minutes to time X:XX
 * @name convert/hoursAndMinutesToTime
 * @param {Object} time
 * @param {number} [time.hours=0]
 * @param {number} [time.minutes=0]
 * @param {string} [separator=:]
 * @returns {string,number} time - string if separator is provided.
 * @example
 *  hoursAndMinutesTotime({ hours: 1, minutes: 8 }, ':') // 01:08
 */
export const hoursAndMinutesToTime = ({ hours = 0, minutes = 0 } = {}, separator = ':') => {
    const hourVal = `00${hours}`.substring((hours.toString().length + 2) - 2);
    const minuteVal = `00${minutes}`.substring((minutes.toString().length + 2) - 2);
    const timestring = `${hourVal}${separator}${minuteVal}`;
    return separator ? timestring : Number(timestring);
};

/**
 * Converts minutes to hours and minutes
 * @name convert/minutesToHoursAndMinutes
 * @param {number} minutes
 * @returns {Object}
 * @example
 *  minutesToHoursAndMinutes(122) // { hours: 2, minutes: 2 }
 */
export const minutesToHoursAndMinutes = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return { hours, minutes: remainingMinutes };
};

/**
 * Converts minutes to hours and minutes
 * @name convert/minutesToTime
 * @param {number} minutes
 * @param {string} [separator]
 * @returns {string} - time
 * @example
 *  minutesToTime(122) // 02:02
 */
export const minutesToTime = (minutes, separator) => {
    const hoursAndMinutes = minutesToHoursAndMinutes(minutes);
    return hoursAndMinutesToTime(hoursAndMinutes, separator);
};

/**
 * Converts a timestamp or string to a date
 * @name convert/timestampToDate
 * @param {string, number} timestamp - accepts timestamps in s and ms, 'now', 'today', 'yesterday', '+X' and '-X' (where X is days), '%X' for day in the current week
 * @param {boolean} midnight - resets the time to 0:00
 * @returns {date}
 */
export const timestampToDate = (timestamp, midnight) => {
    let returnDate;

    if (typeof timestamp === 'string') {
        const today = new Date();
        if (['now', 'today'].includes(timestamp)) {
            returnDate = today;
        } else if (timestamp === 'tomorrow') {
            timestamp = '+1';
        } else if (timestamp === 'yesterday') {
            timestamp = '-1';
        }

        if (timestamp.startsWith('+')) {
            // add days
            today.setDate(today.getDate() + Number(timestamp.substring(1)));
            returnDate = today;
        } else if (timestamp.startsWith('-')) {
            // subtract days
            today.setDate(today.getDate() - Number(timestamp.substring(1)));
            returnDate = today;
        } else if (timestamp.startsWith('%')) {
            // this week
            const weekDay = today.getDay();
            const dateOffset = Number(timestamp.substring(1)) - weekDay;
            today.setDate(today.getDate() + dateOffset);
            returnDate = today;
        }
    } else if (typeof timestamp === 'number') {
        returnDate = (timestamp.toString().length > 10) ? new Date(timestamp) : new Date(timestamp * 1000);
    }

    if (midnight) {
        returnDate.setHours(0, 0, 0, 0);
    }

    return returnDate;
};

export const timeTo = (number, output = 'readable') => {
    let time = null;
    switch (output) {
    case 'readable': {
        time = number.toString();
        const len = time.length;
        if (len > 2) { time = `${time.substring(0, len - 2)}:${time.substring(len - 2)}`; }
        break;
    }

    case 'number':
        time = `${number.hours}${number.minutes < 10 ? `0${number.minutes}` : number.minutes}`;
        time = parseInt(time, 10);
        break;

    case 'minutes': {
        time = number.toString();
        let hours = 0;
        let minutes = 0;
        const len = time.length;
        if (len > 2) {
            hours = time.substring(0, len - 2);
            minutes = time.substring(len - 2);
        }
        hours *= 60;
        time = parseInt(hours, 10) + parseInt(minutes, 10);
        break;
    }

    default:
    }

    return time;
};

const getDateOption = (string) => {
    switch (string) {
    // Weekdays
    case 'DDD': // Sunday
        return ['weekday', 'long'];
    case 'DD': // Sun
        return ['weekday', 'short'];
    case 'D': // S
        return ['weekday', 'narrow'];

        // Year
    case 'yyyy': // 2019
        return ['year', 'numeric'];
    case 'yy': // 19
        return ['year', '2-digit'];

    // Month
    case 'mm': // 03
        return ['month', '2-digit'];
    case 'm': // 3
        return ['month', 'numeric'];
    case 'MMM': // March
        return ['month', 'long'];
    case 'MM': // Mar.
        return ['month', 'short'];

    // Day
    case 'dd': // 07
        return ['day', '2-digit'];
    case 'd': // 7
        return ['day', 'numeric'];

    // Hour
    case 'hh': // 07
        return ['hour', '2-digit'];
    case 'h': // 7
        return ['hour', 'numeric'];

    // Minute
    case 'ii': // 07
        return ['minute', '2-digit'];

    // Second
    case 'ss': // 07
        return ['second', '2-digit'];

    default:
        return ['', ''];
    }
};

export const getOutputTemplate = (template) => {
    const defaults = {
        date: 'DDD d MMM',
        'date-short': 'd MM',
        weekday: 'd DDD',
        'd-m-Y H:i': 'dd mm yyyy hh ii',
        'd-m-Y': 'dd mm yyyy',
    };
    if (Object.keys(defaults).includes(template)) {
        template = defaults[template];
    }

    const parts = template.split(' ');
    const options = {};
    parts.forEach((part) => {
        const val = getDateOption(part);
        options[val[0]] = val[1];
    });
    return options;
};

/**
 * Returns the multiplication of minutes in a timenumber
 * @name convert/timeMultiply
 * @param {number} time
 * @param {number} multiplier
 * @returns {number} time as a number (2x 50m = 140 = 1h 40min)
 * @example
 *  timeMultiply(50, 2) // 140
 */
export const timeMultiply = (time, multiplier) => {
    const date = createDateFromTime(time);
    let new_minutes = date.getMinutes() * multiplier;
    let new_hours = 0;
    const spare_hours = new_minutes > 59 ? Math.floor(new_minutes / 60) : 0;
    new_hours = (date.getHours() * multiplier) + spare_hours;
    new_minutes %= 60;
    return timeTo({ hours: new_hours, minutes: new_minutes }, 'number');
};

export const ymdTo = (ymd, output) => {
    const date = createDate(ymd, 'ymd');
    if (output === 'timestamp') {
        date.setHours(0, 0, 0, 0);
        return Math.floor(date / 1000);
    }
    const output_template = typeof output === 'string' ? getOutputTemplate(output) : output;

    // eslint-disable-next-line
    return date.toLocaleDateString(vue_app._i18n.locale, output_template);
};

export default {
    timestampToDate, timeTo, timeMultiply, ymdTo, getOutputTemplate,
};

import { joinToPretty } from '../assets/helpers/arrays/modify';

export function transformEditpaneDataToCategories(data) {
    data.item = Object.entries(data.item).reduce((acc, [key, value]) => {
        acc[key] = {
            items: value[0].items,
        };
        return acc;
    }, {});

    return Promise.resolve(data);
}

export function transformCategoriesDataToEditpane(data) {
    const visibleCategoriesOnPage = Object.entries(data.context.item).filter(([key, value]) => value.name).map(([key]) => key);
    const groups = Object.entries(data.item).reduce((acc, [key, value]) => {
        if (!visibleCategoriesOnPage.includes(key)) {
            return acc;
        }
        acc[key] = [
            {
                name: '',
                items: value.items,
            },
        ];
        return acc;
    }, {});
    data.item = groups;
    return Promise.resolve(data);
}

const hooks = {
    async editpaneDataToCategories(data) {
        data = await transformEditpaneDataToCategories(data);
        return data;
    },

    async categoriesDataToEditpane(data) {
        data = await transformCategoriesDataToEditpane(data);
        return data;
    },
};

export default hooks;

export function findInCats({
    categories,
    group,
    type = 'map', // map or array
    search = false, // { val: 'id or type', query: ['> confirmed', '< sent' (operator + id/type)] }
    defaultOutput,
    lang,
}) {
    const catsGroup = categories[group];
    if (catsGroup && Array.isArray(catsGroup.items)) {
        if (search) {
            if (typeof search.val !== 'undefined') {
                const searchVal = ['number', 'string'].includes(typeof search.val)
                    ? [search.val]
                    : search.val;
                if (!Array.isArray(searchVal)) {
                    console.warn('category value is incorrect', searchVal, group);
                }
                const results = catsGroup.items.filter(
                    it => searchVal.includes(it.id)
                        || (it.type && searchVal.includes(it.type)),
                );

                if (search.query) {
                    // Use queries like ['< confirmed', '> sent']
                    let queryResults = results;
                    search.query.forEach((query) => {
                        const [operator, compareValue] = query.split(' ');
                        const compareStatus = findInCats({
                            categories,
                            group,
                            type: 'array',
                            search: {
                                val: !Number.isNaN(+compareValue)
                                    ? +compareValue
                                    : compareValue,
                            },
                            defaultOutput: [],
                            lang,
                        });
                        if (operator && compareStatus[0]) {
                            // eslint-disable-next-line
                            const compareFunction = (item) => new Function(
                                `"use strict"; return ${item.sort} ${operator} ${compareStatus[0].sort};`,
                            )();
                            queryResults = queryResults.filter(compareFunction);
                        } else {
                            console.warn('operator or comparestatus not found for', {
                                group, type, search, defaultOutput, query,
                            });
                        }
                    });
                    return queryResults.length > 0;
                }

                if (results.length) {
                    if (typeof defaultOutput === 'string') {
                        return joinToPretty({
                            list: results,
                            key: defaultOutput,
                            max: 2,
                            overflowAmount: true,
                            lang,
                        });
                    }
                    return results;
                }
            }
        } else {
            if (type === 'map') {
                return catsGroup.items_map;
            }
            if (type === 'array') {
                return catsGroup.items;
            }
        }
    } else {
        console.warn('This is no valid category', group);
    }
    return typeof defaultOutput === 'string' ? '' : defaultOutput;
}

const linkify = (val, external = true) => {
    if (typeof val === 'string' && val) {
        const regex = /(?:(?:https?:\/\/)?(?:www\.|(?!www)))([a-zA-Z0-9@]+[a-zA-Z0-9-]\.[^\s<>]{2,})/g;
        const result = val.replace(regex, (match) => {
            let urlPrefix = match.includes('@') ? 'mailto:' : 'https://';
            if (match.startsWith('https://') || match.startsWith('mailto:')) { urlPrefix = ''; }
            return `<a href="${urlPrefix}${match}" ${external ? 'target="_blank"' : ''} rel="nofollow noreferrer">${match}</a>`;
        });
        return result;
    }
    return val;
};

export default linkify;

import { createDate } from '@/assets/helpers/dates/create';
import { getOutputTemplate } from '@/assets/helpers/dates/convert';
import i18n from '@/2/services/language';

// TODO also resolve these as filters
const dates = {
    methods: {
        timestamp_to(timestamp, output, midnight, relative_day) {
            if (!timestamp && !relative_day) { return ''; }
            const date = createDate(timestamp);
            if (midnight) {
                date.setHours(0, 0, 0, 0);
            }
            if (relative_day) {
                let today = new Date();
                today.setHours(0, 0, 0, 0);
                today = today.getTime();
                if (today === date.getTime()) { return i18n.t('dates.today'); }
                if (today + 86400000 === date.getTime()) { return i18n.t('dates.tomorrow'); }
                if (today - 86400000 === date.getTime()) { return i18n.t('dates.yesterday'); }
            }

            if (output === 'timestamp') {
                return Math.floor(date / 1000);
            }
            if (output === 'Y-m-d') {
                return `${date.getFullYear()}-${(`0${date.getMonth() + 1}`).substr(-2)}-${(`0${date.getDate()}`).substr(-2)}`;
            }

            const output_template = typeof output === 'string' ? getOutputTemplate(output) : output;
            const locale = i18n.locale === 'en' ? 'en-GB' : i18n.locale;

            if (output === 'hh ii') {
                return date.toLocaleTimeString(locale, output_template);
            }
            return date.toLocaleDateString(locale, output_template);
        },
    },
};

export default dates;

/**
 * get selector from data by path.
 * @param {object} from - data
 * @param {string} selector - path string, can contain [1] and .
 * @example get(data, 'client.name');
 * @returns object
 */
export function get(from, selector) {
    return selector.replace(/\['(.+)(\.)(.+)'\]/g, '.$1DOT$3')
        .split(/[.[\]]/)
        .filter(Boolean)
        .map((it) => it.replace(/DOT/g, '.'))
        .reduce((prev, cur) => prev && prev[cur], from);
}

/**
 * get recursively nested data by path
 * @param {object} from - data
 * @param {string} selector - path string, must contain [x]. for nested properties, may contain [?]. for optional unlimited nesting.
 * @example getRecursive(data, 'lines[x].children[x].names[?].items);
 * @returns array with objects
 */
export function getRecursive(from, selector) {
    const response = [];

    function optionalReducer(optionalPart) {
        return function reducer(acc, cur) {
            const subset = get(cur, optionalPart);
            if (subset) {
                acc.push(...subset, ...subset.reduce(optionalReducer(optionalPart), []));
            }
            return acc;
        };
    }

    function iterator(subset, remaining_selector) {
        const selectorParts = remaining_selector.split('[x].');
        const selectorRemaining = selectorParts.slice(2).join('[x].');
        let optionalParts;

        if (!selectorParts[1] && selectorParts[0].indexOf('[?].') > -1) {
            // has optional unlimited nested recursion
            optionalParts = selectorParts[0].split('[?].');
            selectorParts[0] = optionalParts[0];
        } else if (selectorParts[1].indexOf('[?].') > -1) {
            // has optional unlimited nested recursion
            optionalParts = selectorParts[1].split('[?].');
            selectorParts[1] = optionalParts[0];
        }

        const firstArray = get(subset, selectorParts[0]);
        if (firstArray) {
            if (selectorParts[1]) {
                firstArray.forEach((arrayData) => {
                    if (selectorRemaining) {
                        iterator(arrayData, `${selectorParts[1]}[x].${selectorRemaining}`);
                    } else {
                        const results = get(arrayData, selectorParts[1]);
                        if (results) {
                            response.push(...results);
                            if (optionalParts) {
                                response.push(...results.reduce(optionalReducer(optionalParts[1]), []));
                            }
                        }
                    }
                });
            } else {
                response.push(...firstArray);
                if (optionalParts) {
                    response.push(...firstArray.reduce(optionalReducer(optionalParts[1]), []));
                }
            }
        }
    }
    iterator(from, selector);

    return response;
}

export const parseTemplate = (template, args, ctx) => {
    try {
        if (typeof template === 'string' && template.includes('return ')) {
            console.debug('template contains `return`');
            return new Function(`"use strict"; ${template}`).bind(ctx, args)(); // eslint-disable-line
        }
        return new Function(`"use strict"; return ${template};`).bind(ctx, args)(); // eslint-disable-line
    } catch (err) {
        console.error('invalid checkIf:', template, err);
        return '';
    }
};

export function parseObject(obj, ctx, recursive) {
    const keys = Object.keys(obj);
    const templateKeys = keys.filter((it) => it.endsWith('_template'));

    if (templateKeys.length) {
        const response = recursive ? obj : JSON.parse(JSON.stringify(obj));

        templateKeys.forEach((key) => {
            const value = response[key];
            const valueType = typeof value;

            if (valueType === 'string') {
                response[key.slice(0, -9)] = parseTemplate(value, undefined, ctx);
            } else if (Array.isArray(value)) {
                response[key.slice(0, -9)] = value.reduce((acc, cur) => {
                    const arrObj = parseObject(cur, ctx, true);
                    acc.push(arrObj);
                    return acc;
                }, []);
            } else { // obj
                response[key.slice(0, -9)] = parseObject(value, ctx, true);
            }
            delete response[key];
        });
        return response;
    }
    return obj;
}

export default {};

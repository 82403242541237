const priceformat = (val = 0, currency = 'EUR', showcurrency = false) => {
    // Sanitize numbers
    if (typeof val === 'string') {
        if (!val.length) {
            return val;
        }
        val = +val.replace(',', '.');
    }
    if (typeof val !== 'number') {
        return val;
    }

    // Prepare Intl settings
    const currencyCountryCodes = {
        EUR: {
            symbol: '€',
            countryCode: 'nl-NL',
            decimals: 2,
        },
        USD: {
            symbol: '$',
            countryCode: 'en-US',
            decimals: 2,
        },
        GBP: {
            symbol: '£',
            countryCode: 'en-GB',
            decimals: 2,
        },
    };

    const params = {
        style: 'decimal',
    };
    const settings = currencyCountryCodes[currency];
    if (!settings) { return val; }
    if (settings.decimals) {
        params.minimumFractionDigits = settings.decimals;
        params.maximumFractionDigits = settings.decimals;
    }

    val = new Intl.NumberFormat(settings.countryCode, params).format(val);

    if (showcurrency) {
        if ((typeof val === 'string' && val.startsWith('-')) || (typeof val === 'number' && val < 0)) {
            if (typeof val === 'number') { val = val.toString(); }
            return `- ${settings.symbol} ${val.substr(1)}`;
        }
        return `${settings.symbol} ${val}`;
    }
    return val;
};

export default priceformat;

<template>
    <component :is="resolved_component" v-if="resolved_component" v-on="$listeners" v-bind="$attrs">
        <!-- Pass on all scoped slots -->
        <template v-for="(_, name) in $slots" v-slot:[name]>
            <slot :name="name" />
        </template>
        <template v-for="(_, name) in $scopedSlots" v-slot:[name]="slotData">
            <slot :name="name" v-bind="slotData" />
        </template>
    </component>
</template>

<script>
export default {
    name: 'ComponentLoader',
    props: {
        component: String,
        type: String,
    },
    data() {
        return {
            resolved_component: null,
        };
    },
    computed: {
        loader() {
            if (!this.type && !this.component) { return null; }
            return () => import('./' + (this.type || this.component)); // eslint-disable-line
        },
    },
    created() {
        this.loader()
            .then(() => {
                this.resolved_component = () => this.loader();
            })
            .catch(() => {
                console.error('Error loading component', this.type, this.component);
            });
    },
};
</script>

interface Tag {
    tag: string;
    attr: {
        [name: string]: string;
    };
}
function selectLinks(): NodeListOf<HTMLLinkElement> {
    return document.head.querySelectorAll(
        'link[rel="icon"], link[rel=stylesheet][title=client-specific]',
    );
}

function remove(): void {
    const links = selectLinks();
    for (let i = 0; i < links.length; i += 1) {
        links[i].remove();
    }
}

function createElement(el: Tag): void {
    const manifestElem = document.head.querySelector<HTMLLinkElement>('link[rel="manifest"]');
    if (el.attr.rel === 'manifest' && manifestElem) {
        // update instead of remove
        manifestElem.href = el.attr.href;
    } else {
        const elem = document.createElement(el.tag);
        Object.assign(elem, el.attr);
        document.head.appendChild(elem);
    }
}

function createElements(namespace: string): void {
    const tags: Tag[] = [
        {
            tag: 'link',
            attr: {
                rel: 'icon',
                href: `/img/icons/${namespace}/favicon.png`,
                type: 'image/png',
            },
        },
        {
            tag: 'link',
            attr: {
                rel: 'icon',
                href: `/img/icons/${namespace}/favicon.svg`,
                type: 'image/svg+xml',
            },
        },
        {
            tag: 'link',
            attr: {
                rel: 'manifest',
                href: `/img/icons/${namespace}/site.webmanifest`,
            },
        },
        {
            tag: 'link',
            attr: {
                rel: 'stylesheet',
                href: `/css/clients/${namespace}.css`,
                title: 'client-specific',
            },
        },
    ];
    tags.forEach(createElement);
}

function create(namespace: string): void {
    if (namespace && typeof namespace === 'string') {
        remove();
        createElements(namespace);
    }
}

export default create;
export const exportedForTesting = {
    selectLinks, remove,
};
